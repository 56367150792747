
import React, { useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { authenticate } from "../../../services/admin/adminService";
const { REACT_APP_OUTBOUND_MENU_ONLY_SHOW } = process.env;

const LoginForm = () => {
  const showOutboundMenu = process.env.REACT_APP_OUTBOUND_MENU_ONLY_SHOW;
  const navigate = useNavigate();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  useEffect(() => {
    //auto login if comes from workflow 
    const paramValue = query.get('loginauto');
    if (paramValue === 'true') {
      if (showOutboundMenu == 'hide' || showOutboundMenu == 'all') {
        navigate("/PossibleResponseGroup");
      }
      else {
        navigate("/WorkFlowManagement");
      }
    }
  }, [query]);

  const onFinish = async (values) => {
    try {
      const response = await authenticate(values.userName, values.password);
      if (response && response.success && response.user != "") {
        sessionStorage.setItem('roleId', response.user.role);
        if (showOutboundMenu == 'hide' || showOutboundMenu == 'all') {
          navigate("/PossibleResponseGroup");
        }
        else {
          navigate("/WorkFlowManagement");
        }
      } else {
        message.error("Credentials mismatch");
      }
    } catch (error) {
      message.error("An error occurred during authentication");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="login"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      style={{ width: 300, padding: '40px', background: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
    >
      <Form.Item
        name="userName"
        rules={[{ required: true, message: 'Please input your Username!' }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Username" autoFocus />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
