import React, {useState, useEffect} from "react";
import {getBatchList, getBatchRecords, uploadBatchFile, processAddBatch, initiateCall, getConvRecordingsAndMessages, getOriginalBatchFile} from '../../../services/workFlowManagementService';
import { Table, Tooltip, Button, Typography, Spin , Form, Upload, Input, Modal, message} from "antd";
import {PhoneFilled, EyeFilled, InfoCircleFilled, ArrowLeftOutlined, LoadingOutlined, UploadOutlined, ReloadOutlined, SyncOutlined, FileExcelFilled, DownloadOutlined} from '@ant-design/icons';
import ConversationModal from '../../components/uploadWorkflow/conversationModal';
import * as XLSX from "xlsx";

const { Text } = Typography;
const antIcon = (<LoadingOutlined  style={{ fontSize: 24 }} spin />);


const BatchInfo = ({projectId,project, batchVisible, setBatchVisible, selectedRowKeys,  setSelectedRowKeys}) => {
    const[batchInfoMetaData, setBatchInfoMetaData] = useState([]);
    const [batchInfoData, setBatchInfoData] = useState([]);
    const [batchRecordData, setBatchRecordData] = useState([]);
    const[batchRecordMetaData, setBatchRecordMetaData] = useState([]);
    const [selectedBatchId, setSelectedBatchId] = useState([]); 
    const [batchLoading, setBatchLoading] = useState(false);
    const [batchForm] = Form.useForm();
    const [batchFileName, setBatchFileName] = useState('');
    const [batchName, setBatchName] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false); 
    const [recordTableLoading, setRecordTableLoading] = useState(false);
    const [isConversationModalVisible, setIsConversationModalVisible] = useState(false);
    const [conversationData, setConversationData] = useState([]);
    const [selectedBatch,setSelectedBatch] = useState([]);
    const [batchDetails, setBatchDetails] = useState([]);

    useEffect(() => {
        if (projectId) {
            fetchBatchData(projectId);
        } else {
            setBatchInfoData([]);
            setBatchInfoMetaData([]);
        }
    }, [projectId]);

    const fetchBatchData = async (projectId) => {
        setBatchLoading(true);
        try {
            const data = await getBatchList(projectId);
            const batchList = data?.batchList|| [];
            setBatchInfoMetaData(batchList);

            const formattedData = batchList.map((item) => ({
                ...item,
                key: item.batchId,
            }));

            setBatchInfoData(formattedData);

        } catch (error) {
            console.error("Error fetching table metadata:", error);
            setBatchInfoMetaData([]);
        }finally{
            setBatchLoading(false);
        }
    };

    const triggerCall = async() => {
        setRecordTableLoading(true)
        try{
            const selectedRecords = batchRecordData.filter((record) =>
                selectedRowKeys.includes(record.payloadId) && record.callStatus !== 'Error'
            );
            if (selectedRecords.length === 0) {
                message.error('No valid records selected');
            } else {
                const inputData = { batchId: selectedBatchId, records: selectedRecords };
                const callResult = await initiateCall(inputData);
                if(callResult && callResult.success){
                    message.success('Call initiated successfully');
                }else{
                    message.error('Failed to initiate call');
                }
            }
        }catch(error){
            console.error(error)
        }finally{
            setRecordTableLoading(false);
        }
    }

      const batchInfoColumns = Array.isArray(batchInfoMetaData) && batchInfoMetaData.length
        ? Object.keys(batchInfoMetaData[0]).map((key) => ({
              title: key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase()),
              dataIndex: key,
              key: key,
              width: 150,
              render: (value) => (value !== null && value !== undefined ? value : "N/A"),
          }))
        : [];

     const batchRecordColumns = batchRecordMetaData.length
        ? Object.keys(batchRecordMetaData[0]).map((key) => ({
            title: key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase()),
            dataIndex: key,
            key: key,
            width: 150,
            // render: (value) => (value !== null && value !== undefined ? value : "N/A"),
            render: (value, record) => {
                if (record.callStatus === 'Error') {
                    return (
                            <span style={{ color: 'red' }}>
                                {value !== null && value !== undefined ? value : "N/A"}
                            </span>
                    );
                }
                return value !== null && value !== undefined ? value : "N/A";
            }
        }))
        : [];

    const fetchBatchRecords = async(record) => {
        setBatchLoading(true);
        try{
            setSelectedBatch(record);
            setSelectedBatchId(record.batchId);
            const result = await getBatchRecords(record.batchId);
            const batchPayLoad = result?.batchData?.payloads || [];
            const formattedData = batchPayLoad.map((item) => ({
                ...item,
                key: item.payloadId,
            }));

            setBatchVisible(true);
            setBatchRecordMetaData(batchPayLoad);
            setBatchRecordData(formattedData);

        } catch (error) {
            console.error("Error fetching table metadata:", error);
            setBatchRecordMetaData([]);
        }finally{
            setBatchLoading(false);
        }
    }

    const closeModal = () => {
        setIsConversationModalVisible(false);
      }

    const constantInfoColumn = [
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <>
                <Tooltip title="Batch Detail">
                    <Button
                        type="link"
                        onClick={() => fetchBatchRecords(record)}
                        style={{padding : '4px 4px'}}
                    >
                        <InfoCircleFilled />
                    </Button>
                </Tooltip>
                <Tooltip title="Download Original File">
                    <Button
                        type="link"
                        onClick={() => getOriginalBatchExcel(record)}
                             icon={
                                <FileExcelFilled style={{color : 'green'}}/>}
                        style={{padding : '4px 4px'}}
                    >
                    </Button>
                </Tooltip>
                <Tooltip title="Download Result File">
                    <Button
                        type="link"
                        onClick={() => handleExcelDownload(record)}
                        style={{
                            // color : '#11714e',
                             padding : '4px 4px'}}
                             icon={
                                <DownloadOutlined />}
                    >
                    </Button>
                </Tooltip>
                
            </>
            ),
            width: 110,
            align: 'center'
            // fixed: 'left',
        },
    ];

    const constantRecordColumn = [
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <>
                {/* <Tooltip title="Initiate Call">
                    <Button
                        type="link"
                        // onClick={() => initiateCall(record)}
                    >
                        <PhoneFilled />
                    </Button>
                </Tooltip> */}
                <Tooltip title="View Conversation">
                    <Button
                        type="link"
                        onClick={() => viewConversation(record)}
                    >
                        <EyeFilled />
                    </Button>
                </Tooltip>
            </>
            ),
            width: 100,
            align: 'center',
        },
    ];
        
    const batchInfoListColumns = [...constantInfoColumn, ...batchInfoColumns];

    const batchRecordListColumns = [...constantRecordColumn,...batchRecordColumns];

    const handleSaveAddBatch = async (val) => {
        const {batchName, fileName} = val;
        try{
            let result = await processAddBatch(projectId, batchName, fileName);
            if (result && result.success) {
                fetchBatchData(projectId);
                handleBatchCancel();
                message.success('Project added successfully');
            }else{
                message.error("Failed to add batch.")
            }
        }catch(error){
          console.error(error);
        }finally{
    
        }
      }

      const handleBatchFileChange = async (info) => {
        const { file } = info;
    
        if (!file || !file.originFileObj) {
          console.error('File object or originFileObj is undefined.');
          return;
        }
    
        const formData = new FormData();
        formData.append('file', file.originFileObj);
    
        let result = await uploadBatchFile(formData);
    
        if (result && result.success) {
          setBatchFileName(result.filename);
          batchForm.setFieldsValue({ fileName: result.filename });
        } else {
          console.error("File upload failed");
        }
    
      };

        const handleBatchCancel = () => {
            setIsModalVisible(false);
            setBatchFileName(null);
            setBatchName('');
            batchForm.resetFields();
        };

        const handleCreateBatch =() => {
            setIsModalVisible(true);
        }

        const handleBackButton = () => {
             setBatchVisible(false);
             setSelectedRowKeys([]);
        }

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys) => {
                setSelectedRowKeys(selectedRowKeys);
            },
            getCheckboxProps: (record) => (
                {
                 disabled: record.callStatus === 'Error', 
                 title: record.callStatus === 'Error' ? 'Error in call status' : '',
                }
            ),
        };
        
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'even-row' : 'odd-row';
        };

        const viewConversation = async (record) => {
            let conId = record?.conversationId;
            if (conId) {
                // setLoading(true);
                try {
                    let result = await getConvRecordingsAndMessages(conId);
                    setConversationData({
                        recordings: JSON.parse(result.recordings),
                        messages: JSON.parse(result.conversationMessages)
                    });
                    setIsConversationModalVisible(true);
                } catch (error) {
                    Modal.info({ title: 'Info', content: 'Conversation Unavailable.' });
                } finally {
                    // setLoading(false);
                }
            }else {
                Modal.info({ title: 'Info', content: 'Conversation ID not found.' });
            }
        }

        const handleRefresh = async () => {
           await fetchBatchRecords(selectedBatch);
        }


const exportToExcel = (data, filename, columns) => {
    const wb = XLSX.utils.book_new();

    // Create an array for the header row (with bold styling)
    const headerRow = columns.map(col => ({
        v: col,  // Value of the header
        s: { font: { bold: true } }  // Apply bold style
    }));

    // Create data rows (no bold styling)
    const dataRows = data.map(row => columns.map(col => row[col] || "N/A"));

    // Combine the header and data rows
    const worksheetData = [headerRow, ...dataRows];

    // Create the worksheet from the data
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Batch Data");

    // Write the file
    XLSX.writeFile(wb, `${filename}.xlsx`);
    message.success("File Downloaded Successfully");
};

        
        const handleExcelDownload = async (record) => {
            try {
                const result = await getBatchRecords(record.batchId);
                const batchReport = result?.batchData;
        
                if (!batchReport || !batchReport.payloads || batchReport.payloads.length === 0) {
                    message.warning("No records available to download.");
                    return;
                }
        
                // Extract batch metadata keys dynamically (excluding 'payloads')
                const batchMetadataKeys = Object.keys(batchReport).filter(key => key !== 'payloads');
        
                // Extract payload keys dynamically from the first payload (assuming all payloads have the same structure)
                const payloadsKeys = batchReport.payloads.length > 0 ? Object.keys(batchReport.payloads[0]) : [];
        
                // Combine batch metadata with payload data for export
                const formattedData = batchReport.payloads.map((item) => {
                    const rowData = {};
                    
                    // Add batch metadata fields to each row
                    batchMetadataKeys.forEach(key => {
                        rowData[key] = batchReport[key] || "N/A";
                    });
        
                    // Add payload fields to each row
                    payloadsKeys.forEach(key => {
                        rowData[key] = item[key] || "N/A";
                    });
        
                    return rowData;
                });
        
                // Dynamically generate columns for the export based on keys
                const columns = [
                    ...batchMetadataKeys, // Add batch metadata columns
                    ...payloadsKeys, // Add payload-specific columns
                ];
        
                // Export to Excel with bold header
                exportToExcel(formattedData, `Batch_Records_${record.batchId}`, columns);
        
            } catch (error) {
                console.error("Error downloading batch data:", error);
                message.error("Failed to download the batch data.");
            }
        };
        
        const getOriginalBatchExcel = async (record) => {
            try {
                    const link = document.createElement('a');
                    link.href = process.env.REACT_APP_BOT_API_URI + "wfm/getBatchFile?fileName=" + record.fileName;
                    link.download = record.fileName || 'download.xlsx';
                    link.target = "_self";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
            } catch (error) {
                console.error("Error while downloading the file:", error);
            }
        }

        const getOriginalProjectExcel = async (record) => {
            try {
                if (record.fileName){
                    const link = document.createElement('a');
                    link.href = process.env.REACT_APP_BOT_API_URI + "wfm/getProjectFile?fileName=" + record.fileName;
                    link.download = record.fileName || 'download.xlsx';
                    link.target = "_self";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                else{
                    message.error('Project File Unavailable')
                }
            } catch (error) {
                console.error("Error while downloading the file:", error);
            }
        }

    return(
        <>
        <Spin spinning={batchLoading} indicator={antIcon}>
        {!batchVisible ?
           (<>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10}}>
                    <div>
                        <Text strong style={{ fontSize: 17 }}>
                            Batch Info:
                        </Text>
                    </div>
                    <div  style={{ display: 'flex', alignItems : 'center'}}>
                        <Button type="primary" style={{fontWeight : '600'}} onClick={handleCreateBatch}>Create Batch</Button>
                        <Tooltip title="Download Project File">
                            <Button size='large' type = 'link' onClick={() => getOriginalProjectExcel(project)} style={{marginLeft : 3}} icon={<FileExcelFilled style={{marginTop : 3 , color : 'green', fontSize: '24px'}}/>}></Button>
                        </Tooltip>
                    </div>
                </div>
                {!batchInfoMetaData.length ? (
                    <Text>No batch info available.</Text>
                ) : (
                <Table
                        columns={batchInfoListColumns}
                        dataSource={batchInfoData}
                        pagination={{  showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items` }} 
                        bordered
                        className='batch-table'
                        scroll={{ x: 'max-content' }} 
                        rowClassName={getRowClassName}
                    />
                )}

                
                    <Modal
                    title="Add New Batch"
                    open={isModalVisible}
                    onCancel={handleBatchCancel}
                    footer={null}
                    >
                    <Form layout="vertical" form={batchForm}
                    onFinish={handleSaveAddBatch}
                    >
                        <Form.Item label="Batch Name"
                        name="batchName"
                        rules={[{ required: true , message: "Enter a Batch Name"}]}>
                        <Input
                            value={batchName}
                            onChange={(e) => setBatchName(e.target.value)}
                            placeholder="Enter Batch Name"
                        />
                        </Form.Item>

                        <Form.Item label="Upload File" name="fileName"
                        rules={[{ required: true, message: "Upload a file"}]}
                        >
                        <Upload
                            name="file"
                            accept=".xlsx,.xls"
                            onChange={handleBatchFileChange}
                            showUploadList={false}
                            customRequest={() => {}}
                        >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                        {batchFileName && (
                            <div style={{ marginTop: '10px' }}>
                            <strong>Selected File:</strong> {batchFileName}
                            </div>
                        )}
                        </Form.Item>

                        <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Add Batch
                        </Button>
                        </Form.Item>
                    </Form>
                    </Modal>
            </>
        ):(
            <>
            <div 
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10}}>
                        {/* Label on the left */}
                    <div>
                        
                        <Text strong style={{ fontSize: 17, marginRight :5 }}>
                            Batch Record:
                        </Text>
                        
                    <Button size='small' type="link" icon={batchLoading ? <SyncOutlined spin /> : <ReloadOutlined />} onClick={handleRefresh}></Button>
                    </div>

                        {/* Buttons on the right */}
                        <div>
                            
                            <Button 
                                type="" 
                                className="call-btn"
                                onClick={() => triggerCall()}
                                disabled = {selectedRowKeys.length === 0} > Call <PhoneFilled /> 
                            </Button>
                        
                            <Button 
                            type="default" 
                            onClick={handleBackButton} 
                            style={{ marginLeft: 10 }}
                            onMouseEnter={(e) =>{
                                e.currentTarget.style.backgroundColor = '#187bcd'; 
                                e.currentTarget.querySelector('svg').style.fill = '#ffffff';
                              } }
                            onMouseLeave={(e) =>{
                                e.currentTarget.style.backgroundColor = '';
                                e.currentTarget.querySelector('svg').style.fill = '';
                            }}
                            >
                            <ArrowLeftOutlined/>
                            </Button>
                            
                        </div>
                    </div>
                    <Spin className='rotate' indicator={antIcon} spinning={recordTableLoading}>
                        <Table
                        columns={batchRecordListColumns}
                        dataSource={batchRecordData}
                        pagination={{ showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items` }} 
                        bordered
                        className='batch-table'
                        scroll={{ x: 'max-content' }} 
                        rowSelection={rowSelection}
                        rowClassName={getRowClassName}
                        />
                    </Spin>
            </>
            )
        }
            <ConversationModal 
                isModalOpen ={isConversationModalVisible} 
                onCloseModal ={closeModal} 
                conversationRecording ={conversationData.recordings || []}
                loadingConversation ={batchLoading} 
                conversationMessages={conversationData.messages || []} 
            />
        </Spin>
        </>
    )
}

export default BatchInfo;