import React, { useState, useEffect } from "react";
import { Transfer, message, Tabs, Button, InputNumber, Typography, Space } from "antd";
import {processUpdateProject} from '../../../services/workFlowManagementService'
const { TabPane } = Tabs;

const { Text } = Typography;

const ConfigurationTransfer = ({ selectedProject, targetKeys, setTargetKeys }) => {
  const data = JSON.parse(selectedProject?.metaData);
  // const uiMetadata = JSON.parse(selectedProject?.uiMetadata)  || [];
  
  // const defaultTargetKeys = uiMetadata ? 
  //           uiMetadata.filter((meta) => meta.key && meta.show).map((meta) => meta.key): [];

  // useEffect(() => {
  //   if (targetKeys.length === 0 && defaultTargetKeys.length > 0) {
  //     setTargetKeys((prevKeys) =>
  //       prevKeys.length === 0 ? defaultTargetKeys : prevKeys
  //     );
  //   }
  // }, []);

  const handleChange = (newTargetKeys) => {
    if (newTargetKeys.length > 5) {
      message.warning("You can only select up to 5 items.");
      return;
    }
    setTargetKeys(newTargetKeys);
  };

  const groupedData = Object.keys(data).map((categoryKey) => {
    return {
      category: categoryKey,
      items: Object.keys(data[categoryKey]).map((key) => ({
        key: key,
        title: key,
        isHeader: false,
      })),
    };
  });

  const combinedItems = Object.keys(data).flatMap((categoryKey) => {
    return Object.keys(data[categoryKey]).map((key) => ({
      key: key,
      title: key,
    }));
  });

  const renderItem = (item) => {
    const capitalizedTitle = item.title.charAt(0).toUpperCase() + item.title.slice(1);

    return {
      label: <span style={{ paddingLeft: "10px" }}>{capitalizedTitle}</span>,
      value: item.key,
    };
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "70%" ,  backgroundColor: "rgb(243 243 243)", padding : '10px 20px', borderRadius : " 8px"}}>
          <h3 style={{ textAlign: "center", marginBottom: "10px", marginTop:"0px" }}>
            Metadata
          </h3>
        <Transfer
        className="custom-transfer"
          dataSource={combinedItems}
          targetKeys={targetKeys}
          onChange={handleChange}
          render={renderItem}
          showSearch
          titles={["Source", "Selected"]}
          listStyle={{
            width: 700,
            height: 425,
            border: "1.5px solid #776666",
          }}
          rowKey={(record) => record.key}
          filterOption={(inputValue, item) =>
            item.title.toLowerCase().includes(inputValue.toLowerCase())
          }
          showSelectAll={false}
        />
      </div>
    </div>
  );
};

const ConfigurationPage = ({ selectedProject, clientInfoTrigger, setClientInfoTrigger }) => {

  const [activeTab, setActiveTab] = useState("1");
  const [targetKeys, setTargetKeys] = useState([]);
  const [maxRetryCount, setMaxRetryCount] = useState('3');

  console.log(selectedProject?.maxRetryCount);

  let uiMetaData = [];
  try {
    if (selectedProject?.uiMetadata) {
        uiMetaData = JSON.parse(selectedProject?.uiMetadata);
    }
  } catch (error) {
      console.error("Failed to parse selectedProject.uiMetadata:", error);
  }

  const defaultTargetKeys = uiMetaData ? uiMetaData?.filter((meta) => meta.key && meta.show).map((meta) => meta.key): [];

  const defaultMaxRetryCount = selectedProject?.maxRetryCount || '3';

  useEffect(() => {
    if (targetKeys.length === 0 && defaultTargetKeys.length > 0) {
      setTargetKeys((prevKeys) =>
        prevKeys.length === 0 ? defaultTargetKeys : prevKeys
      );
    }

    if (defaultMaxRetryCount){
      setMaxRetryCount(defaultMaxRetryCount)
    }

  }, []);

  const handleSaveConfiguration =async () => {
    try{  
      const formattedUIMetaData = targetKeys.map((key) => ({
        key: key,
        show: true,
        // displayName: key.charAt(0).toUpperCase() + key.slice(1),
        displayName: key,
        wrap: true,
        width: 200,
      }));
      
      const finalUIMetaData = formattedUIMetaData.length === 0 ? null : formattedUIMetaData;
      const res = await processUpdateProject(selectedProject.projectId, selectedProject.projectName, finalUIMetaData, maxRetryCount);

      if (res?.success) {
        setClientInfoTrigger(true);
        message.success("Saved successfully!");
      } else {
        message.error(res?.message || "Failed to save. Please try again.");
      }
    }catch(error){
      message.error('Error Saving Configuration')
    }
  }

  const handleResetConfiguration = () => {
    setTargetKeys(defaultTargetKeys);
    setMaxRetryCount(defaultMaxRetryCount);
    message.info("Configuration reset to default values.");
  }
  
  const extraContent = (
    <>
      <Button type="" onClick={handleResetConfiguration} style={{marginRight : 6}}>
        Reset
      </Button>
      <Button type="primary" onClick={handleSaveConfiguration}>
        Save
      </Button>
    </>
  );

  const onInputNumberChange = (value) => {
    setMaxRetryCount(value);
  }

  return (
    <Tabs defaultActiveKey="1" onChange={(key)=>setActiveTab(key)} tabBarExtraContent={(activeTab === "1" || activeTab === "2") && (extraContent)}>
      <TabPane tab="Metadata Config" key="1">
        <ConfigurationTransfer selectedProject={selectedProject} targetKeys={targetKeys} setTargetKeys={setTargetKeys}/>
      </TabPane>
      <TabPane tab="Settings" key="2">
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Text strong>Max Retry Count :</Text>
            <InputNumber
              min={1}
              max={10}
              defaultValue={3}
              value={parseInt(maxRetryCount, 10)}
              onChange={onInputNumberChange}
              style={{ width: "120px" }}
            />
          </div>
        </Space>
      </TabPane>
    </Tabs>
  );
};

export default ConfigurationPage;
