import axios from 'axios';
import axiosInterceptor from "../middleware/axiosInterceptor";

export const fetchBranchInitialData = async () => {
    const url = "/api/workflow/Component/branch/initial";
    try {
        const response = await axiosInterceptor.get(url);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};
export const fetchComponentInitialData = async () => {
    const url = "/api/workflow/Component/create/initial";

    try {
        const response = await axiosInterceptor.get(url);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }

};

export const fetchComponentCategoryCreateUpdateData = async (categoryId, categoryName) => {
    const url = "/api/workflow/Component/category/create";
    const bodyData = { categoryId, categoryName };
    try {
        const response = await axiosInterceptor.post(url, bodyData);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};
export const fetchWorkFlowData = async () => {
    const url = "/api/workflow/Components";

    try {
        const response = await axiosInterceptor.get(url);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }

};
export const saveWorkflowComponent = async (data) => {
    const url = "/api/workflow/Component/create";
    try {
        const response = await axiosInterceptor.post(url, data);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }

};
export const saveStoredProcedure = async (data) => {
    const url = "/api/workflow/StoredProcedure/create";
    try {
        const response = await axiosInterceptor.post(url, data);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }

};
export const UpdateWorkflowComponent = async (componentId) => {
    const url = `/api/workflow/Component/${componentId}`;
    try {

        const response = await axiosInterceptor.get(url);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching workflow component:", error);
        throw error;
    }
};
export const DeleteWorkflowComponent = async (componentId) => {
    const url = `/api/workflow/Component/Delete/${componentId}`;
    try {

        const response = await axiosInterceptor.delete(url);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching workflow component:", error);
        throw error;
    }
};
export const UpdateWorkflowCategory = async (categoryId) => {
    const url = `/api/workflow/Category/${categoryId}`;
    try {

        const response = await axiosInterceptor.get(url);
        return response.data;

    }
    catch (error) {
        console.error("Error fetching workflow component:", error);
        throw error;
    }
};
export const DeleteWorkflowCategory = async (formData) => {
    const url = `uploadFile`;
    try {

        const response = await axiosInterceptor.post(url);
        return response.data;

    }
    catch (error) {
        console.error("Error fetching workflow component:", error);
        throw error;
    }
};
export const DeleteStoreProcedure = async (storeProcedureListId) => {
    const url = `/api/workflow/StoreProcedure/Delete/${storeProcedureListId}`;
    try {

        const response = await axiosInterceptor.delete(url);
        return response.data;

    }
    catch (error) {
        console.error("Error fetching workflow component:", error);
        throw error;
    }
};

// export const UploadFile = async (formData) => {
//     const url = `/api/uploadFile`;
//     try {    
//         const response = await axiosInterceptor.post(url, formData);
//         console.error(response.data);
//         return response.data;        
//     }
//     catch (error) {
//         console.error("Error fetching workflow component:", error);
//         throw error; 
//     }
// };

export const convertJsonArray = async (WorkflowJson) => {
    try {
        const url = `/api/create-workflow-json`;
        const response = await axiosInterceptor.post(url, WorkflowJson);
        return response;
    }
    catch (error) {
        console.log("Error fetching workflow component:", error);
        throw error;
    }
};

export const saveWorkflow = async (WorkflowJson) => {
    try {
        //const url = `/api/workflow/savedigram`;
        const url = `/api/workflow/save`;
        const response = await axiosInterceptor.post(url, WorkflowJson);
        return response;
    }
    catch (error) {
        console.log("Error fetching workflow component:", error);
        throw error;
    }
};

export const updateMasterTable = async (WorkflowJson) => {
    try {
        const url = `/api/workflow/updatemaster`;
        const response = await axiosInterceptor.post(url, WorkflowJson);
        return response;
    }
    catch (error) {
        console.log("Error fetching workflow component:", error);
        throw error;
    }
};

export const saveApiFunctions = async (data) => {
    const url = "/api/workflow/apifunction/create";
    try {
        const response = await axiosInterceptor.post(url, data);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const getParametersByGroupId = async (paramaterGroupId) => {
    const url = `/api/workflow/parameter/get/${paramaterGroupId}`;
    try {
        const response = await axiosInterceptor.get(url);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching workflow component:", error);
        throw error;
    }
};
