import axiosInterceptor from "../middleware/axiosInterceptor";
const baseURL = process.env.REACT_APP_BOT_API_URI;

export const getClients = async () => {
    const url = baseURL + "wfm/getClients";
    console.log(url);
    try {
        const response = await axiosInterceptor.get(url);
        return response.data.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const getClientInfo = async (clientId) => {
    const url = baseURL + "wfm/getClientInfo";
    try{
        const response = await axiosInterceptor.post(url, {"clientId" : clientId});
        return response.data.data;
    }catch(error){
        console.error(error);
    }
}

export const getBatchList = async (projectId) => {
    const url = baseURL + "wfm/getBatchList";
    try{
        const response = await axiosInterceptor.post(url, {"projectId" : projectId});
        return response.data.data;
    }catch(error){
        console.error(error);
    }
}

export const getBatchRecords = async (batchId) => {
    const url = baseURL + "wfm/getBatchRecords";
    try{
        const response = await axiosInterceptor.post(url, {"batchId" : batchId});
        return response.data.data;
    }catch(error){
        console.error(error);
    }
}

export const processAddGroup = async (clientId , groupName) => {
    const url = baseURL + "wfm/addGroup";
    try{
        console.log(url);
        const response = await axiosInterceptor.post(url, {"clientId" : clientId, "groupName" : groupName});
        console.log(response.data);
        return response.data;
    }catch(error){
        console.error(error);
    }
}

export const processUpdateGroup = async (groupid , groupName) => {
    const url = baseURL + "wfm/updateGroup";
    try{
        console.log(groupName);
        const response = await axiosInterceptor.post(url, {"groupId" : groupid, "groupName" : groupName});
        console.log(response.data);
        return response.data;
    }catch(error){
        console.error(error);
    }
}

export const uploadProjectFile = async (fileData) => {
    const url = baseURL + "wfm/uploadProject";
    try{
        const response = await axiosInterceptor.post(url, fileData, {
            headers: {
              'Content-Type': 'multipart/form-data', 
            },
          });
        console.log('service',response.data);
        return response.data;
    }catch(error){
        console.error(error);
    }
}

export const processAddProject = async (projectName,projectType, fileName, contextGroupId) => {
    const url = baseURL + "wfm/addProject";
    try{
        const response = await axiosInterceptor.post(url, {"groupId" : contextGroupId, 
            "projectName" : projectName, "projectTypeId": projectType, "metaData" : null, "configuration" : null, "fileName" : fileName});
        console.log(response.data);
        return response.data;
    }catch(error){
        console.error(error);
    }
}

export const uploadBatchFile = async (fileData) => {
    const url = baseURL + "wfm/uploadRecord";
    try{
        const response = await axiosInterceptor.post(url, fileData, {
            headers: {
              'Content-Type': 'multipart/form-data', 
            },
          });
        console.log('service',response.data);
        return response.data;
    }catch(error){
        console.error(error);
    }
}

export const processAddBatch = async (projectId, batchName, fileName) => {
    const url = baseURL + "wfm/addBatch";
    try{
        console.log({"projectId" : projectId, 
            "batchName" : batchName, "fileName" : fileName});

        const response = await axiosInterceptor.post(url, {"projectId" : projectId, 
            "batchName" : batchName, "fileName" : fileName});
        console.log(response.data);
        return response.data;
    }catch(error){
        console.error(error);
    }
}

export const initiateCall = async (payload) => {
    const url = baseURL + "wfm/initCall";
    try{
        const response = await axiosInterceptor.post(url,payload);
        console.log(response.data);
        return response.data;
    }catch(error){
        console.error(error);
    }
}

export const getConvRecordingsAndMessages = async (conId) => {
    const url =  baseURL + `wfm/getConversationInfo?cid=${conId}`;
    console.log(url);
    try {
        const response = await axiosInterceptor.get(url);
        return response.data.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const getPatientList = async(patientName) => {
    const url = baseURL + `wfm/getPatientList`;
    try{
        const response = await axiosInterceptor.post(url, {"patientName": patientName});
        console.log(response.data);
        return response.data;
    }catch(error) {
        console.error(error);
    }
}

export const getPatientinfo = async(fromDate,todate, patientId) => {
    const url = baseURL + `wfm/getPatientInfo`;
    console.log({fromDate,todate, patientId})
    try{
        console.log(patientId);
        const response = await axiosInterceptor.post(url, {"fromDate": fromDate, "toDate" : todate,"patientIDList": patientId});
        return response.data.data;
    }catch(error) {
        console.error(error);
    }
}

export const getProjectTypeList = async () => {
    const url = baseURL + "wfm/getProjectTypeLookups";
    console.log(url);
    try {
        const response = await axiosInterceptor.get(url);
        return response.data.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const processUpdateProject = async (projectId , projectName, uiMetaData, retryCount) => {
    const url = baseURL + "wfm/updateProject";
    try{
        const response = await axiosInterceptor.post(url, {"projectId" : projectId, "projectName" : projectName, "uiMetaData" : uiMetaData, "retryCount": retryCount});
        console.log(response.data);
        return response.data;
    }catch(error){
        console.error(error);
    }
}