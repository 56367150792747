import React, { useState, useEffect } from 'react';
import { Form, Button, Table, Tag, Drawer, Select, Space, Input, InputNumber, Typography, Popconfirm, Dropdown, Divider, Card, Col, Row, Modal, message, Tooltip, Checkbox, Flex, Spin } from 'antd';
import { EditOutlined, DeleteOutlined, SettingOutlined, GroupOutlined, RollbackOutlined, LinkOutlined, FlagOutlined, PhoneOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { fetchBranchInitialData, UpdateWorkflowComponent } from '../../../services/componentService.js';
import { passOutputToParent, isJsonString } from '../../../assets/js/common.js';
const { REACT_APP_COMPONENT_VIEW_RESTRICT } = process.env;

var AdminRoleId = "2";
const { Option } = Select;
const CONST_MAPPING = {
    NEXT_WORKFLOW: "NEXTWORKFLOW",
    SAME_COMPONENT: "SAMECOMPONENT",
    PROCESS_TYPE_API: 'API CONFIG',
    PROCESS_TYPE_SP: 'SP CALL',
    PROCESS_TYPE_MESSAGE: 'MESSAGE BUILD',
    OPERATOR_WORKFLOW_ID: 2,
    OPERATOR_LINE_TEXT: 'operator',
    MULTISELECT_MAX_DISPLAY: 2,
}
const isRestrictComponentView = REACT_APP_COMPONENT_VIEW_RESTRICT;
const ApiCall = () => {

    const [form] = Form.useForm();
    const [formres] = Form.useForm();
    const [nodeKey, setNodeKey] = useState("");
    const [masterConfig, setMasterConfig] = useState("");
    const [tableData, setTableData] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [lineFlowText, setLineFlowText] = useState('');
    const [showWorkflowDropdown, setShowWorkflowDropdown] = useState(false);
    const [functionNames, setFunctionNames] = useState([]);
    const [isUserRestricted, setIsUserRestricted] = useState(false);
    const [listSp, setListSp] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [settingEditKey, setSettingEditKey] = useState('');
    const [settingSelectOptions, setSettingSelectOptions] = useState([]);
    const [settingSelectedKey, setSettingSelectedKey] = useState(null);
    const [showSettings, setshowSettings] = useState(false);
    const [componetKeyId, setComponetKeyId] = useState("");
    const [hasRun, setHasRun] = useState(false);
    const [showLineFields, setShowLineFields] = useState(false);
    const [loading, setLoader] = useState(true);
    const [settingCount, setSettingCount] = useState(false);

    const [functionNameId, setFunctionNameId] = useState('0');
    const [functionNameApi, setApiFunctionName] = useState('');
    const [spId, setSpId] = useState('0');
    const [spName, setSpName] = useState('');

    //Node state
    const [componentEndCall, setComponentEndCall] = useState(false);
    const [componentEndOperator, setComponentEndOperator] = useState(false);
    const [pauseRecord, setpauseRecord] = useState(false);
    const [messageType, setMessageType] = useState('Voice');

    //Call config
    const [incomingResponseTypeData, setIncomingResponseTypeData] = useState([]);
    const [speechTimeoutNum, setspeechTimeout] = useState(0);
    const [ivrCharLimit, setIvrCharLimit] = useState(0);
    const [incomingResponseType, setIncomingResponseType] = useState([]);

    //#region PossibleResponse
    //possible Response
    const [toggleResponseDrawer, setToggleResponseDrawer] = useState(false);
    const [PossibleResponseGroups, setPossibleResponseDropdown] = useState([]);
    //const [selectedPossibleResponse, setPossibleResponseSelectedItems] = useState([]);
    const [possibleResponseData, setpossibleResponseData] = useState([]);
    const [priorityNumber, setPriorityMaxNumber] = useState(30);
    const [isResponseEditing, setIsResponseEditing] = useState(false);
    const [editResponseItem, setResponseEditingItem] = useState(null);
    const [resGroupText, setResponseGroupText] = useState('');

    const clickPossibleDrawer = () => {
        setToggleResponseDrawer(true);
    }
    const handlePossibleResponseChange = (value, option) => {
        setResponseGroupText(option.children);
    };

    const editResponseGroupItem = (record) => {
        formres.setFieldsValue(record);
        setResponseEditingItem(record);
        setResponseGroupText(record.keywordText);
        setIsResponseEditing(true);
    };

    const onchangeApiFunction = (value, option) => {
        setFunctionNameId(option.key);
        setApiFunctionName(option.children);
    }
    const onchangeSP = (value, option) => {
        setSpId(option.key);
        setSpName(option.children);
    }



    const addResponseItem = async () => {
        try {
            const values = await formres.validateFields(['keywordTextId', 'keywordPriority']);
            if (isResponseEditing) {
                if (possibleResponseData.some(item => item.keywordTextId === values.keywordTextId && item.keywordTextId !== editResponseItem.keywordTextId)) {
                    return;
                }
                else if (possibleResponseData.some(item => item.keywordPriority === values.keywordPriority && item.keywordPriority !== editResponseItem.keywordPriority)) {
                    return;
                }
                const selectedText = resGroupText;
                const updatedData = possibleResponseData.map((item) =>
                    item.responseGroupId === editResponseItem.responseGroupId ? { ...item, keywordText: selectedText, ...values } : item
                );

                setpossibleResponseData(updatedData);
                setResponseEditingItem(null);
                setIsResponseEditing(false);
            } else {
                if (possibleResponseData.some(item => item.keywordTextId === values.keywordTextId)) {
                    return;
                }
                else if (possibleResponseData.some(item => item.keywordPriority === values.keywordPriority)) {
                    return;
                }
                const selectedText = resGroupText;
                const newData = {
                    responseGroupId: possibleResponseData.length ? possibleResponseData[possibleResponseData.length - 1].responseGroupId + 1 : 1,
                    keywordText: selectedText, // Store selected keywordText in table column
                    ...values,
                };
                setpossibleResponseData([...possibleResponseData, newData]);
            }
            formres.resetFields();
        } catch (errorInfo) {
            console.log('Validate Failed:', errorInfo);
        }
    };

    const deleteResponseGroupItem = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            onOk() {
                setpossibleResponseData(possibleResponseData.filter((item) => item.responseGroupId !== id));
            },
            onCancel() {
            },
        });
    };
    const generateIntegerOptions = (max) => {
        let options = [];
        for (let i = 0; i <= max; i++) {
            options.push(i);
        }
        return options;
    };
    const columnsResponseGroup = [
        { title: 'ID', dataIndex: 'responseGroupId', key: 'responseGroupId', hidden: true, width: 100, },
        { title: 'Keyword', dataIndex: 'keywordTextId', key: 'keywordTextId', width: 100, hidden: true },
        { title: 'Keyword', dataIndex: 'keywordText', key: 'keywordText', width: 100 },
        { title: 'Priority', dataIndex: 'keywordPriority', key: 'keywordPriority', width: 100 },
        {
            title: 'Actions', width: 60, key: 'actions', render: (text, record) =>
            (<> <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} onClick={() => editResponseGroupItem(record)}> <EditOutlined className='text-color-black' /> </Button>
                <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} danger onClick={() => deleteResponseGroupItem(record.responseGroupId)}> <DeleteOutlined /> </Button> </>),
        },
    ];
    //#endregion

    //#region page load
    /*
    ------First load to show/hide setting screen based on master and user-----
    ------Load Master config Data from 'Component Builder'------------
    */
    const componentBuilderLoad = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams != null && urlParams != "") {
            const keyFromQuery = urlParams.get('config');
            const compId = urlParams.get('compId');
            if (keyFromQuery != null) {
                if (keyFromQuery.toLocaleLowerCase() === 'master') {
                    setLoader(false);
                    setMasterConfig(keyFromQuery);
                    setshowSettings(true);
                    setShowLineFields(true);
                }
                if (compId != null && compId != "" && compId != undefined && compId != "null") {
                    setComponetKeyId(compId);
                    fetchMasterConfiguredData(compId, null);
                }
            }
        }
        else {
            setMasterConfig("master-comp");
            console.log("Component opened in workflow");
        }
    }

    useEffect(() => {
        fetchPopulateData(); // Page intial data load         
        componentBuilderLoad(); //For set data in componet builder page    
        fetchComponentData(); //Set the configured value in workflow                    
        if (masterConfig != null && masterConfig != "" && masterConfig.toLocaleLowerCase("master-comp")) {
            if (isRestrictComponentView.toLowerCase() == "true") {
                setshowSettings(true);
                setShowLineFields(true);
                setMasterConfig('master-comp');
                setLoader(false);
            };
        };
    }, [])

    useEffect(() => {
        setShowLineFields(true);
    }, [masterConfig]);

    useEffect(() => {
        if (!hasRun && !showSettings) {
            const timeoutId = setTimeout(() => {
                setLoader(true);
                handleSelectChange(settingSelectedKey);
                setHasRun(true);
                setLoader(false);
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [settingSelectedKey, gridData, hasRun]);

    //useEffect(() => {
    // Add event listener to listen for messages from the parent window
    // async function fetchComponentData() {
    //     try {
    //         window.addEventListener('message', compDataLoad);
    //         return () => {
    //             window.removeEventListener('message', compDataLoad);
    //         };
    //     } catch (error) {
    //         console.error('Error fetching location data:', error);
    //     } finally {
    //     }
    // }
    //  fetchComponentData();
    //}, []);

    async function fetchPopulateData() {
        try {
            const response = await fetchBranchInitialData();
            if (response.data !== undefined) {

                setFunctionNames(response.data.functionName);
                setListSp(response.data.spList);
                setWorkflows(response.data.workFlows);
                setPossibleResponseDropdown(response.data.possibleResponseGroup);
                setIncomingResponseTypeData(response.data.incomingResponseType);

            }
        } catch (error) {
            console.error('Error fetching location data:', error);
        } finally {
        }
    }

    async function fetchComponentData() {
        try {
            window.addEventListener('message', compDataLoad);
            return () => {
                window.removeEventListener('message', compDataLoad);
            };
        } catch (error) {
            console.error('Error fetching location data:', error);
        } finally {
        }
    }

    async function fetchMasterConfiguredData(compKeyId, selectedData) {
        try {
            if (compKeyId != "" && compKeyId !== null) {
                const response = await UpdateWorkflowComponent(compKeyId);
                if (response.hasOwnProperty("component")) {
                    const compJson = JSON.parse(response.component.config);
                    if (compJson) {
                        const data = compJson.loadJson;
                        var updatedMainData = data || [];
                        if (selectedData !== null && selectedData !== undefined) {
                            let selectedJson = selectedData.data;

                            if (typeof selectedData.data !== 'object') {
                                selectedJson = JSON.parse(selectedData.data);
                            } else {
                                selectedJson = selectedData.data;
                            }

                            if (selectedJson.loadJson.hasOwnProperty('configData')) {
                                const choosedData = selectedJson;
                                updatedMainData = await updatedMainJson(data, choosedData);
                            }
                        }
                        if (updatedMainData.length > 1) {
                            setSettingCount(true);
                        }
                        //console.log("setGridData:", updatedMainData);
                        setGridData(updatedMainData);
                        //console.log("updateSelectOptions", updatedMainData);
                        updateSelectOptions(updatedMainData);
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching fetchMasterConfiguredData :', error);
        } finally {
        }
    }

    const updatedMainJson = (masterData, selectedData) => {
        // Access the correct path for selectedData configData
        const selectedConfig = selectedData.loadJson.configData.configData;
        const updatedMasterJson = masterData.map(mainItem => {
            const mainConfig = JSON.parse(mainItem.configuration);
            // If nameKey matches, update the main JSON with the input configData
            if (mainConfig.configData.nameKey === selectedConfig.nameKey) {
                mainConfig.configData = selectedConfig; // Update configData with selected configData                
                return {
                    ...mainItem,
                    configuration: JSON.stringify(mainConfig) // Convert updated object back to string
                };
            }
            return mainItem;
        });

        //console.log("updatedMainData:", updatedMasterJson);
        return updatedMasterJson;
    };


    //#endregion

    const updateSelectOptions = (settingGridData) => {
        const newOptions = settingGridData.map(item => ({
            label: item.name,
            value: item.key,
        }));

        //console.log("newOptions", newOptions)
        setSettingSelectOptions(newOptions);
        //console.log("setSettingSelectOptionsView", setSettingSelectOptions)
        //console.log("settingSelectOptions", settingSelectOptions)
    };

    const handleSelectChange = (selectedKey) => {
        setSettingSelectedKey(selectedKey); // Update the selected key
        setshowSettings(true);
        setSettingEditKey(selectedKey);
        if (gridData != undefined && gridData != null) {
            const filteredData = filterByKey(gridData, selectedKey);
            setFieldValues(filteredData[0]);
        }
    };

    // Function to filter based on key
    const filterByKey = (data, key) => {
        return data.filter(item => item.key === key);
    };

    //#region Load Component Data From Parent Window
    const compDataLoad = (event) => {
        //debugger;
        if (event.source === window.parent) {
            if (event.data != null && event.data != "" && event.data != undefined) {
                //Unique node 'KEY' Get from parent and set output json to appropriate Component
                setNodeKey(event.data.key);
                setComponetKeyId(event.data.componentId || "");
                //set component main configs

                fetchMasterConfiguredData(event.data.componentId || "", event.data || null);
                const incomingData = event.data;
                if (isJsonString(incomingData.data)) {
                    const loadData = JSON.parse(incomingData.data);
                    if (loadData != null) {
                        if (loadData.loadJson != null && loadData.loadJson != undefined) {
                            var processData = loadData.loadJson;
                            if (typeof loadData.loadJson !== 'object') {
                                processData = JSON.parse(loadData.loadJson);
                            }
                            if (processData != null && processData != "") {
                                if (processData.configData != null && processData.configData != "") {
                                    setSettingSelectedKey(processData.configData.configData.nameKey);
                                }
                            }
                        }
                    }
                }
                else {

                    let loadData = incomingData.data;
                    if (typeof incomingData.data !== 'object') {
                        loadData = JSON.parse(incomingData.data);
                    } else {
                        loadData = incomingData.data;
                    }

                    if (loadData != null) {
                        if (loadData.loadJson != null && loadData.loadJson != undefined) {
                            var processData = loadData.loadJson;
                            if (typeof loadData.loadJson !== 'object') {
                                processData = JSON.parse(loadData.loadJson);
                            }
                            if (processData != null && processData != "") {
                                if (processData.configData != null && processData.configData != "") {
                                    setSettingSelectedKey(processData.configData.configData.nameKey);
                                }
                            }
                        }
                    }
                }
            }
        }
    };
    //#endregion

    const handleLineTypeChange = (value) => {
        if (value === 'nextworkflow') {
            setShowWorkflowDropdown(true);
        } else {
            setShowWorkflowDropdown(false);
        }
    };

    //#region Component End stage
    const onComponentEndCallChange = (e) => {
        setComponentEndCall(e.target.checked);
    }
    const onComponentEndOperatorChange = (e) => {
        setComponentEndOperator(e.target.checked);
    }
    const onPauseRecordChange = (e) => {
        setpauseRecord(e.target.checked);
    }
    //#endregion

    //#region Call config
    const onChangeCallLatency = (value) => {
        setspeechTimeout(value);
    }

    const onChangeIvrCharLimit = (value) => {
        setIvrCharLimit(value);
    }
    const ChangeIncomingResponseType = (value) => {
        setIncomingResponseType(value);
    }
    const ChangeMessageType = (value) => {
        setMessageType(value);
    }

    //#endregion

    const handleWorkflowChange = (value) => {
        const selectedWorkflow = workflows.find(wf => wf.workFlowId === value);
        setLineFlowText(selectedWorkflow ? selectedWorkflow.workFlowName : '');
    };

    const handleLineAdd = async () => {
        await form.validateFields(['lineType', 'lineNextFlow', 'lineText']).then(values => {
            const newData = {
                key: tableData.length ? tableData[tableData.length - 1].key + 1 : 1,
                lineType: values.lineType,
                lineNextFlow: values.lineNextFlow || '',
                lineNextFlowText: lineFlowText,
                lineText: values.lineText,
            };
            setTableData([...tableData, newData]);
            setLineFlowText('');
            setShowWorkflowDropdown(false);
            form.resetFields(['lineType', 'lineNextFlow', 'lineText']); // Clear the fields after adding
        }).catch(errorInfo => {
            console.log('Validation failed:', errorInfo);
        });
    };

    const handleLineEdit = (record) => {
        setEditingKey(record.key);
        form.setFieldsValue(record);
        setShowWorkflowDropdown(record.lineType === 'nextworkflow');
        setLineFlowText(record.lineNextFlowText || '');
        setShowLineFields(true);
    };
    const handleCancel = () => {
        resetAllFields();
        setSettingEditKey('');
    };
    const handleLineDelete = (key) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            onOk() {
                setTableData(tableData.filter(item => item.key !== key));
            },
            onCancel() {
            },
        });
    };

    const handleLineUpdate = async () => {
        await form.validateFields(['lineType', 'lineNextFlow', 'lineText']).then(values => {
            const updatedData = tableData.map(item =>
                item.key === editingKey ? { ...item, ...values, lineNextFlowText: lineFlowText } : item
            );
            setTableData(updatedData);
            setEditingKey('');
            form.resetFields(['lineType', 'lineNextFlow', 'lineText']);
            setLineFlowText('');
            setShowWorkflowDropdown(false);
        }).catch(errorInfo => {
            console.log('Validation failed:', errorInfo);
        });
        if (masterConfig == "" || masterConfig == null) {
            setShowLineFields(false);
        }
    };


    const handleAddGrid = async () => {
        var sendGridData = [...gridData] || [];
        await form.validateFields(['name', 'api', 'sp']).then(values => {
            const currentGridData = gridData || [];
            var newKeyId = currentGridData.length && currentGridData[currentGridData.length - 1].key != null
                ? Number(currentGridData[currentGridData.length - 1].key) + 1 : 1;

            if (settingEditKey != "" && settingEditKey !== null && settingEditKey !== undefined) {
                newKeyId = settingEditKey;
            }

            //Get Line section Data
            const updateLines = tableData.map((item) => {
                const lineLblText = item.lineText.split('|')[0];
                if (item.lineType.toUpperCase() === CONST_MAPPING.NEXT_WORKFLOW) {
                    return { ...item, lineText: `${lineLblText}|${item.lineNextFlow}` };
                } else if (item.lineType.toUpperCase() === CONST_MAPPING.SAME_COMPONENT) {
                    return { ...item, lineText: `${lineLblText}|${CONST_MAPPING.SAME_COMPONENT}` };
                }
                return item;
            });

            //Default line Items
            const defaultLines = { key: -1, lineType: "nextworkflow", lineNextFlow: CONST_MAPPING.OPERATOR_WORKFLOW_ID, lineText: CONST_MAPPING.OPERATOR_LINE_TEXT + "|" + CONST_MAPPING.OPERATOR_WORKFLOW_ID };
            let linesData = { lines: updateLines };
            linesData.lines = [...linesData.lines, defaultLines];

            const processTypeData = [];
            if (values.api) {
                processTypeData.push({
                    processId: newKeyId,
                    processType: "API Config",
                    data: {
                        functionType: "API",
                        //functionNameId: values.api,
                        functionNameId: functionNameId,
                        functionNameApi: functionNameApi.toLowerCase() == 'select' ? '' : functionNameApi,
                    }
                });
            }
            if (values.sp) {
                processTypeData.push({
                    processId: newKeyId,
                    processType: "SP Call",
                    data: {
                        //spId: values.sp,
                        spId: spId,
                        spName: spName.toLowerCase() == 'select' ? '' : spName,
                    }
                });
            }

            const newGridData = {
                key: newKeyId, // Set keyid
                name: values.name,
                configuration: JSON.stringify({
                    configData: {
                        name: values.name,
                        nameKey: newKeyId,
                        config: processTypeData,
                        endCall: componentEndCall ? 1 : 0, //Get Component End Call;  Converts the boolean to 1 or 0
                        connectOperator: componentEndOperator ? 1 : 0, //Get Component End Call Operator; Converts the boolean to 1 or 0
                        pauseRecording: pauseRecord ? 1 : 0, //Get Component pauseRecord; Converts the boolean to 1 or 0
                        communicationType: messageType, //messageType 'Text' or Voice
                        speechTimeout: speechTimeoutNum, //Get Call Listen Latency
                        ivrCharacterLimit: ivrCharLimit, //Get Call ivr Char Limit
                        incomingTextType: incomingResponseType, //Get Incoming Response TYPE
                        possibleResponse: possibleResponseData,
                        configLines: linesData,
                        selected: settingSelectedKey === settingEditKey || false,
                    },
                })
            };

            // Check if the item with the same key already exists in gridData
            const existingIndex = currentGridData.findIndex(item => item.key === settingEditKey);

            if (existingIndex !== -1) {
                // Update the existing item
                const updatedGridData = [...currentGridData];
                updatedGridData[existingIndex] = { ...updatedGridData[existingIndex], ...newGridData };
                sendGridData = [...updatedGridData];
                setGridData(updatedGridData);
                updateSelectOptions(updatedGridData);
            } else {
                // Add the new item
                sendGridData = [...currentGridData, newGridData];
                setGridData([...currentGridData, newGridData]);
                updateSelectOptions([...currentGridData, newGridData]);
            }

            resetAllFields();

        }).catch(errorInfo => {
            console.log('Validation failed:', errorInfo);
        });
        return sendGridData;
    };

    const handleGridEdit = (record) => {
        setSettingEditKey(record.key);
        setFieldValues(record);
    };

    const setFieldValues = (data) => {
        //resetAllFields();
        if (data != undefined && data != null) {
            let rowData = data;
            if (typeof data !== 'object') {
                rowData = JSON.parse(data);
            }
            var actualData = rowData.configuration;
            if (typeof actualData !== 'object') {
                actualData = JSON.parse(actualData);
            }
            actualData = actualData.configData;
            const name = actualData.name;
            const apiDataObj = actualData?.config?.find(item => item?.data?.functionNameId);
            const spDataObj = actualData?.config?.find(item => item?.data?.spId);

            const apiData = apiDataObj?.data?.functionNameId || '';
            const spData = spDataObj?.data?.spId || '';
            //form.setFieldsValue({ name: name, api: apiData, sp: spData });
            const apiDataName = apiDataObj?.data?.functionNameApi || '';
            const spDataName = spDataObj?.data?.spName || '';

            // console.log("apiog data:", apiDataObj);
            // console.log("apiDataid:", apiData);
            // console.log("apiDataName:", apiDataName);

            setApiFunctionName(apiDataName || '');
            setFunctionNameId(apiData || '0');

            setSpName(spDataName || '');
            setSpId(spData || '0');


            form.setFieldsValue({ name: name, api: apiDataName || apiData, sp: spDataName || spData });

            if (actualData) {
                setpossibleResponseData(actualData.possibleResponse || []);
                // Load data for Component End stage
                setComponentEndCall(actualData.endCall == 1 ? true : false || false)
                setComponentEndOperator(actualData.connectOperator == 1 ? true : false || false)
                setpauseRecord(actualData.pauseRecording == 1 ? true : false || false)
                setMessageType(actualData.communicationType || 'Voice')

                //Load call configs
                setspeechTimeout(actualData.speechTimeout || 0)
                setIvrCharLimit(actualData.ivrCharacterLimit || 0)
                setIncomingResponseType(actualData.incomingTextType || [])
            }
            const lineItems = actualData.configLines;
            if (lineItems) {
                //Remove Default Lines and Bind to table
                lineItems.lines = lineItems.lines.filter(line => line.key !== -1);
                const processedLines = lineItems.lines.map(item => ({ ...item, lineText: item.lineText.split('|')[0] }));
                setTableData(processedLines || []);
            }
        }
    }

    const resetAllFields = () => {
        setSettingEditKey('');

        setTableData([]);
        setpossibleResponseData([]);

        setspeechTimeout(0);
        setIvrCharLimit(0);
        setIncomingResponseType([]);

        setComponentEndCall(false);
        setComponentEndOperator(false);
        setpauseRecord(false);
        setMessageType('Voice');

        form.resetFields(); // Clear all form fields
        formres.resetFields(); // Clear possible response table        
    }

    const handleGridDelete = (key) => {
        const updatedGridData = gridData.filter(item => item.key !== key);
        setGridData(updatedGridData);
        updateSelectOptions(updatedGridData);
        // If the deleted item was selected, clear the selection
        if (settingSelectedKey === key) {
            setSettingSelectedKey(null);
        }
    };

    const handleSave = async () => {
        if (settingSelectedKey !== undefined && settingSelectedKey != null) {
            //update seleted dropdown settings
            const UpdatedGridData = await handleAddGrid();
            const selectedSetting = UpdatedGridData.filter((item) => item.key === settingSelectedKey);
            if (selectedSetting != undefined && selectedSetting != null && selectedSetting.length > 0) {
                const selectedData = JSON.parse(selectedSetting[0].configuration);
                const fullData = { key: nodeKey, configData: selectedData || null, configLines: selectedData.configData.configLines || null };
                console.log("Flow Save:", fullData);
                passOutputToParent(JSON.stringify(fullData));
            }
        }
        else {
            console.log("Selection setting is empty or not valid");
        }
    };

    const handleMasterSave = () => {
        console.log("handleMasterSave:", gridData);
        passOutputToParent(gridData);
    }

    const columnsLine = [
        { title: 'Type', dataIndex: 'lineType', key: 'lineType' },
        { title: 'Text', dataIndex: 'lineText', key: 'lineText' },
        { title: 'Workflow', dataIndex: 'lineNextFlowText', key: 'lineNextFlowText' },
        { title: 'Workflow ID', dataIndex: 'lineNextFlow', key: 'lineNextFlow', hidden: true },
        {
            title: 'Action', key: 'action',
            render: (text, record) => (
                <Space>
                    <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} onClick={() => handleLineEdit(record)}><EditOutlined /></Button>
                    <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} onClick={() => handleLineDelete(record.key)}><DeleteOutlined /></Button>
                </Space>
            ),
        },
    ];

    const gridColumns = [
        { title: 'key', dataIndex: 'key', key: 'key', hidden: true },
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Configuration', dataIndex: 'configuration', key: 'configuration', hidden: true },
        { title: 'selected', dataIndex: 'selected', key: 'selected', hidden: true },
        {
            title: 'Action', key: 'action',
            render: (text, record) => (
                <Space>
                    <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} onClick={() => handleGridEdit(record)}><EditOutlined /></Button>
                    <Popconfirm
                        title="Are you sure you want to delete this record?"
                        onConfirm={() => handleGridDelete(record.key)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    // console.log("setApiFunctionName", functionNameApi);
    // console.log("setFunctionNameId", functionNameId);
    // console.log("spid", spId);
    // console.log("spname:", spName);

    return (
        <div style={{ padding: '10px' }}>
            <Form form={form} name='form-lines' layout="vertical">
                {masterConfig.toLowerCase() == "master-comp" && (
                    settingCount && (
                        <>
                            <h4 style={{ margin: '0' }}>Select the setting</h4>
                            <Select
                                style={{ width: 200, marginBottom: '10px' }}
                                options={settingSelectOptions}
                                placeholder="Select an option"
                                onChange={handleSelectChange}
                                value={settingSelectedKey}
                            />
                        </>
                    )
                )}
                {masterConfig != "" && (
                    <>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Field should not be empty' }]}>
                                    <Input placeholder="Enter Name" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item initialValue={'0'} label="API" name="api" rules={[{ required: true, message: 'Field should not be empty' }]}>
                                    <Select
                                        onChange={onchangeApiFunction}
                                        value={functionNameApi != "" ? functionNameApi : functionNameId}>
                                        <Option key={'0'} value="0">Select</Option>
                                        {functionNames.map((option) => (
                                            // <Option key={option.functionCallListId} value={option.functionCallListId}>{option.apiFunctionName}</Option>
                                            <Option key={option.functionCallListId} value={functionNameApi != "" ? option.apiFunctionName : option.functionCallListId}>{option.apiFunctionName}</Option>
                                        ))}
                                    </Select>

                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item initialValue={'0'} label="SP" name="sp" rules={[{ required: false, message: 'Field should not be empty' }]}>
                                    <Select
                                        onChange={onchangeSP}
                                        value={spName != "" ? spName : spId}>
                                        <Option key={'0'} value="0">Select</Option>
                                        {listSp.map((option) => (
                                            // <Option key={option.storeProcedureListId} value={option.storeProcedureListId}>{option.list}</Option>
                                            <Option key={option.storeProcedureListId} value={spName != "" ? option.list : option.storeProcedureListId}>{option.list}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider dashed />
                    </>)}
                <Spin spinning={loading}>
                    {showSettings && (
                        <Card className='cus-card' title={<Row align="middle"><Col><LinkOutlined className='cus-card-title-icon' /></Col><Col><span>Line Section</span></Col></Row>}>
                            {showLineFields &&
                                (
                                    <>
                                        <Row gutter={24}>
                                            <Col span={6}>

                                                <Form.Item label="Line Type" name="lineType" rules={[{ required: true, message: 'Field should not be empty' }]}>
                                                    <Select onChange={handleLineTypeChange} style={{ height: 28 }} placeholder="Select">
                                                        <Option key="nextcomponent" value="nextcomponent">Next Component</Option>
                                                        <Option key="nextworkflow" value="nextworkflow">Next Workflow</Option>
                                                        <Option key="samecomponent" value="samecomponent">Same Component</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            {showWorkflowDropdown && (
                                                <Col span={6}>
                                                    <Form.Item label="Select Next Workflow" name="lineNextFlow" initialValue={0}
                                                    // rules={[
                                                    //     { required: true, message: 'Field should not be empty' },
                                                    //     {
                                                    //         validator: (_, value) =>
                                                    //             value !== 0 ? Promise.resolve() : Promise.reject('Field should not be empty')
                                                    //     }
                                                    // ]}
                                                    >
                                                        <Select onChange={handleWorkflowChange} style={{ height: 28 }}>
                                                            <Option value={0}>Select</Option>
                                                            {workflows.map((option) => (
                                                                <Option key={option.workFlowId} value={option.workFlowId}>{option.workFlowName}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            )}
                                            <Col span={6}>
                                                <Form.Item label="Line Text" name="lineText" rules={[{ required: true, message: 'Field should not be empty' }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item style={{ textAlign: 'right', textAlign: 'left', marginTop: '28px' }}>
                                                    {editingKey === '' ? (
                                                        <Button className='ant-btn-sm' type="primary" onClick={handleLineAdd}>Add</Button>
                                                    ) : (
                                                        <Button className='ant-btn-sm' type="primary" onClick={handleLineUpdate}>Update</Button>
                                                    )}
                                                </Form.Item>
                                            </Col></Row>
                                    </>
                                )}
                            <Table columns={columnsLine.filter(col => !col.hidden)} className='cus-table' dataSource={tableData} pagination={false} />
                        </Card>
                    )}
                </Spin>
            </Form>

            <Spin spinning={loading}>
                {
                    showSettings && (
                        <>
                            <Divider dashed />
                            <div>
                                <Card className='cus-card cus-card-flag'
                                    title={
                                        <Row align="middle">
                                            <Col>
                                                <FlagOutlined className='cus-card-title-icon' />
                                            </Col>
                                            <Col>
                                                <span>Component Flag indicator</span>
                                                <span style={{ paddingLeft: "10px" }}>
                                                    <Tooltip title="If this is the final state of the component, only check it; if not, leave it unchecked.">
                                                        <ExclamationCircleOutlined />
                                                    </Tooltip>
                                                </span>
                                            </Col>
                                        </Row>
                                    }
                                >
                                    <Row gutter={[20, 20]}>
                                        <Col>
                                            <Checkbox checked={componentEndCall} onChange={onComponentEndCallChange}>End Conversation</Checkbox>
                                        </Col>
                                        <Col>
                                            <Checkbox checked={componentEndOperator} onChange={onComponentEndOperatorChange}>Connect Operator</Checkbox>
                                        </Col>
                                        <Col>
                                            <Checkbox checked={pauseRecord} onChange={onPauseRecordChange}>Pause Record</Checkbox>
                                        </Col>
                                        <Col span={[10]}>
                                            <Row span={[24]}>
                                                <Col span={[10]}>
                                                    <div className='cus_lable'>
                                                        <label>Message Type</label>
                                                        <span style={{ paddingLeft: "10px" }}>
                                                            <Tooltip title="Change message type either 'Text' or 'Voice'. Default type is 'Voice'">
                                                                <ExclamationCircleOutlined />
                                                            </Tooltip>
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col span={[12]} style={{ marginBottom: "10px" }}>
                                                    <Select style={{ width: "100%" }} value={messageType} placeholder="Select" onChange={ChangeMessageType}>
                                                        <Option text="Voice" value="Voice"></Option>
                                                        <Option text="Text" value="Text"></Option>
                                                        <Option text="Text" value="DDMF"></Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <Divider dashed />
                            <div>
                                <Card className='cus-card'
                                    title={
                                        <Row align="middle">
                                            <Col>
                                                <PhoneOutlined className='cus-card-title-icon' />
                                            </Col>
                                            <Col>
                                                <span>Call Configuration</span>
                                                <span style={{ paddingLeft: "10px" }}>
                                                    <Tooltip title="Set values if the step needs this configuration otherwise leave it as ZERO(0) in number">
                                                        <ExclamationCircleOutlined />
                                                    </Tooltip>
                                                </span>
                                            </Col>
                                        </Row>
                                    }
                                >
                                    <Row gutter={[20, 20]}>
                                        <Col>
                                            <div className='cus_lable' style={{ marginBottom: "10px" }}>
                                                <label>Speech Timeout</label>
                                            </div>
                                            <InputNumber value={speechTimeoutNum} min={0} max={10000} onChange={onChangeCallLatency} />
                                        </Col>
                                        <Col>
                                            <div className='cus_lable' style={{ marginBottom: "10px" }}>
                                                <label>IVR Character Limit</label>
                                            </div>
                                            <InputNumber value={ivrCharLimit} min={0} max={35} onChange={onChangeIvrCharLimit} />
                                        </Col>
                                        <Col span={[8]}>
                                            <div className='cus_lable' style={{ marginBottom: "10px" }}>
                                                <label>Response Type</label>
                                                <span style={{ paddingLeft: "10px" }}>
                                                    <Tooltip title="Select if you expect an incoming response 'TYPE' otherwise leave it empty">
                                                        <ExclamationCircleOutlined />
                                                    </Tooltip>
                                                </span>
                                            </div>
                                            <Select style={{ width: "100%" }} mode='multiple' value={incomingResponseType} placeholder="Select" onChange={ChangeIncomingResponseType}>
                                                {incomingResponseTypeData.map((option) => (
                                                    <Option key={option.responseId} value={option.responseId}>{option.responseName}</Option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <br />
                            <Flex gap="small" wrap>
                                <>  <Button icon={<GroupOutlined />} type="primary" onClick={clickPossibleDrawer}>Possible Response</Button></>
                            </Flex>
                            <Row gutter={16} style={{ marginTop: 20 }}>
                                <Col span={24}>
                                    <Form.Item style={{ textAlign: 'right' }}>
                                        {masterConfig != "" && (
                                            <>
                                                {(settingEditKey != '' && settingEditKey != null && settingEditKey != undefined) ? (
                                                    <Button className='ant-btn-sm' type="primary" onClick={handleAddGrid}>Update Setting</Button>
                                                ) : (
                                                    <Button className='ant-btn-sm' type="primary" onClick={handleAddGrid}>Add Setting</Button>
                                                )}
                                            </>)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Drawer
                                title="Possible Response"
                                className='compDrawer'
                                onClose={() => setToggleResponseDrawer(false)}
                                open={toggleResponseDrawer}
                            >
                                {/* <Card className='cus-card'
                            title={
                                <Row align="middle">
                                    <Col>
                                        <GroupOutlined className='cus-card-title-icon' />
                                    </Col>
                                    <Col>
                                        <span>Possible Response Keyword</span>
                                    </Col>
                                </Row>
                            }
                        > */}
                                <Form form={formres} name='form-possible-response' layout="vertical">
                                    <Row gutter={[20, 20]}>
                                        <Col span={8}>
                                            <Form.Item
                                                name="keywordTextId"
                                                label="Possible Response Keyword"
                                                rules={[{ required: true, message: 'Field should not be empty' }]}
                                            >
                                                <Select
                                                    placeholder="select"
                                                    onChange={handlePossibleResponseChange} style={{ width: '100%', height: 28 }}
                                                >
                                                    {PossibleResponseGroups.map((option) => (
                                                        <Option key={option.groupId} value={option.groupId} name={option.groupName}>{option.groupName}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="keywordPriority"
                                                label="Priority"
                                                initialValue={0}
                                            >
                                                <Select>
                                                    {generateIntegerOptions(priorityNumber).map((num) => (
                                                        <option key={num} value={num}>{num}</option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item style={{ textAlign: 'right', marginTop: '25px' }}>
                                                <Button className='ant-btn-sm' type="primary" onClick={addResponseItem}>
                                                    {isResponseEditing ? 'Update' : 'Add'}
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <Table scroll={{ x: 'max-content', y: 150 }} pagination={false} className='cus-table' columns={columnsResponseGroup} dataSource={possibleResponseData} rowKey="responseGroupId" />
                            </Drawer>
                        </>
                    )
                }
                {
                    masterConfig != "" &&
                    (
                        <div>
                            <Divider dashed />
                            <Card className='cus-card' title={<Row align="middle"><Col><LinkOutlined className='cus-card-title-icon' /></Col><Col><span>Settings</span></Col></Row>}>
                                <Table style={{ display: 'block' }} className='cus-table' columns={gridColumns} dataSource={gridData} pagination={false} />
                            </Card>
                        </div>
                    )
                }
            </Spin>
            <div>
                <Row justify="end" gutter={8}>
                    <Col>
                        {(masterConfig != "" && masterConfig.toLowerCase() == "master") ?
                            (<Button type="primary" htmlType="submit" onClick={handleMasterSave}>Configuration Save</Button>)
                            :
                            (<Button type="primary" htmlType="submit" onClick={handleSave}>Save</Button>)
                        }

                    </Col>
                    <Col>
                        <Button type="default" onClick={handleCancel}>
                            Clear
                        </Button>
                    </Col>
                </Row>
            </div>
        </div >
    );
};

export default ApiCall;
