import React, {useState, useRef, useEffect} from 'react';
import {Table, Spin, Tabs, Space, Modal, Card} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

const antIcon = (<LoadingOutlined style={{fontSize: 24}} spin/>);

function ConversationModal({isModalOpen, onCloseModal, conversationRecording, 
    loadingConversation, conversationMessages}){
    const audioRef = useRef(null);

    useEffect(() => {
        if (!isModalOpen && audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0; 
        }
    }, [isModalOpen]);

    return(
        <Modal
            centered
            width={900}
            title={"Conversation"}
            bodyStyle={{ height: 'calc(100vh - 115px)', overflowY: "hidden" }}
            open={isModalOpen}
            onCancel={onCloseModal}
            footer={null}
            maskClosable={false}
        >
            <Spin className="rotate" indicator={antIcon} spinning={loadingConversation}>
                {conversationRecording.length > 0 ? (
                    conversationRecording[0].recordingFilePath ? (
                        <div style={{ padding: '10px 0' }}>
                            <audio style={{ height: 28, width: 250 }} controls ref={audioRef}>
                                <source
                                    src={conversationRecording[0].recordingFilePath}
                                    type="audio/wav"
                                />
                            </audio>
                        </div>
                    ) : null
                ) : null}

                {/* <div style={{ display: 'flex' }}> */}
                    <div style={{ padding:10,overflowY: 'auto', maxHeight: 'calc(100vh - 250px)'}}>
                        {conversationMessages.length > 0
                            ? conversationMessages.map((conversation,index) => (
                                <div key={index}>
                                    <div>
                                        {conversation.messageType === 'sent' ?  (
                                            <div className='outgoing_msg'>
                                                <div className='sent_msg'>
                                                    <p>{conversation.message}</p>
                                                    <span className='time_date'>
                                                        {conversation.createdAt}
                                                    </span>
                                                </div>
                                            </div>
                                        ):
                                        (
                                            <div className='received_msg'>
                                                <div className='received_with_msg'>
                                                    <p>{conversation.message}</p>
                                                    <span className='time_date'>
                                                        {conversation.createdAt}
                                                    </span>
                                                </div>
                                            </div>
                                        ) }
                                    </div>
                                </div>
                            ))
                            : ''}

                    </div>
                {/* </div> */}
             </Spin>
        </Modal>
    )
}

export default ConversationModal;