import React, { useState, useEffect } from 'react';
import { Select, Button, Table, Spin, message, Input, Form, Space, Popover, Tooltip, Modal, Row, Col, Typography, Card, DatePicker  } from 'antd';
import {getPatientList, getPatientinfo, getConvRecordingsAndMessages} from '../../../services/workFlowManagementService'
import '../../../../src/assets/styles/patientView.css';
import { EyeFilled, FileExcelFilled, FileTextFilled, FileTextOutlined, FilterFilled, SearchOutlined } from '@ant-design/icons';
import ConversationModal from '../../components/uploadWorkflow/conversationModal';
import FormItemLabel from 'antd/es/form/FormItemLabel';


const { Option } = Select;

const tailLayout = {
  wrapperCol: {offset: 6, span: 16}
};

const {RangePicker} = DatePicker;

const { Text, Title } = Typography;

const data = [
  {"id": 36795, "patientName": "Srivathsan Yytest", "identification": "93544786", "age": 25, "phone": "4153086769"},
  {"id": 36950, "patientName": "Ashwin Yytest", "identification": "93545053", "age": 22, "phone": "4155690596"}
];


// const dynamicExtractPatientData = (data) => {
//   return data.map(patient => {
//       const patientFields = Object.keys(patient.patientData);
//       let extractedData = { id: patient.patientData.identification };
//       patientFields.forEach(field => {
//           extractedData[field] = patient.patientData[field];
//       });
//       return extractedData;
//   });
// };

const dynamicExtractPatientData = (data) => {
  return data.map(patient => {
      const patientFields = Object.keys(patient.patientData);
      let extractedData = {};
      patientFields.forEach(field => {
          if (field !== 'id') {
              extractedData[field] = patient.patientData[field];
          }
      });
      return extractedData;
  });
};


const PatientView = () => {
  const [patientId, setPatientId] = useState([]);
  const [patientDetails, setPatientDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [patientFilterForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [payload, setPayload] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isConversationModalVisible, setIsConversationModalVisible] = useState(false);
  const [conversationData, setConversationData] = useState([]);
  const [patientinfo, setPatientInfo] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [resetFlag, setResetFlag] = useState(false);
  const [dates, setDates] = useState(['2024-11-01', '2024-12-31']);
  const [selectedRange, setSelectedRange] = useState('thisMonth');

  useEffect(()=>{
    fetchPatientDetails(dates);
  }, [])

  useEffect(() => {
    setFilteredData(patientDetails);
  }, [resetFlag]);

  const onDateChange = (values, dateStrings) => {
    setDates(dateStrings);
    fetchPatientDetails(dateStrings);
  };

  const patientColumns =patientinfo.length > 0 ?
   Object.keys(patientinfo[0]).map(key => ({
    title: key.charAt(0).toUpperCase() + key.slice(1),
    dataIndex: key,
    key,
    className :'expanded-table-header',
    // sorter: (a, b) => {
    //   if (a[key] == null) return 1;
    //   if (b[key] == null) return -1;
    //   return a[key].toString().localeCompare(b[key].toString());
    // },
  })):[];


  const handleSearch = async (value) => {
    try {
      if (value.length > 2) {
        const result =await getPatientList(value);
        setOptions(JSON.parse(result.data) || []);
      }
    } catch (error) {
      message.error('Error fetching patient data');
    }
  };

  const filteredPayload = payload? Object.entries(payload).filter(([key, value]) =>
      key.toLowerCase().includes(searchText) ||
      (value !== null && value.toString().toLowerCase().includes(searchText))
    )
  : [];

  const handleSelect = (value) => {
    setPatientId((prevSelected) => {
      const newSelected = Array.isArray(value) ? value : [value];
      return [...new Set([...prevSelected, ...newSelected])];
    });
  };

  const handleDeselect = (value) => {
    setPatientId((prevSelected) => prevSelected.filter(id => id !== value));
  };

  const fetchPatientDetails = async (dateStrings) => {
    // if (!patientId) {
    //   message.error('Please select a patient');
    //   return;
    // }
    setLoading(true);
    try {
      const finalPatientId = patientId.length === 0 ? null : patientId; 
      const [startDate, endDate] = dateStrings;

      const patientData = await getPatientinfo(startDate, endDate, finalPatientId);
      const extractedPatientData = dynamicExtractPatientData(patientData);
      setPatientDetails(patientData);
      setFilteredData(patientData);
      setPatientInfo(extractedPatientData);

      const uniqueGroups = [
        ...new Set(patientData.flatMap(patient => patient.groupInfo).map(group => group.group_name))
      ];

      const uniqueProjects = [
        ...new Set(patientData.flatMap(patient => patient.groupInfo).map(project => project.project_name))
      ]
    
      setGroupOptions(uniqueGroups);
      setProjectOptions(uniqueProjects);
    } catch (error) {
      console.log(error);
      message.error('Error fetching patient details');
    } finally {
      setLoading(false);
    }
  };

  const handleActionClick = (record) => {
    const payload = JSON.parse(record.record_payload);
    setPayload(payload);
    setIsModalVisible(true); 
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setPayload(null);
    setSearchText('');
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const getOriginalBatchExcel = async (record) => {
    try {
            const link = document.createElement('a');
            link.href = process.env.REACT_APP_BOT_API_URI + "wfm/getBatchFile?fileName=" + record.file_name;
            link.download = record.fileName || 'download.xlsx';
            link.target = "_self";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    } catch (error) {
        console.error("Error while downloading the file:", error);
    }
}

const viewConversation = async (record) => {
  let conId = record?.conversation_id;
  if (conId) {
      // setLoading(true);
      try {
          let result = await getConvRecordingsAndMessages(conId);
          setConversationData({
              recordings: JSON.parse(result.recordings),
              messages: JSON.parse(result.conversationMessages)
          });
          setIsConversationModalVisible(true);
      } catch (error) {
          message.error('Error fetching conversation data');
      } finally {
          // setLoading(false);
      }
  }else {
      Modal.info({ title: 'Info', content: 'Conversation ID not found.' });
  }
}

const closeModal = () => {
  setIsConversationModalVisible(false);
  setConversationData([]);
}

// const batchLevelColumns = [
//   { title: 'Batch Name', dataIndex: 'batch_name', key: 'batch_name', className : 'batch-table-header' },
//   { title: 'Batch File', dataIndex: 'file_name', key: 'file_name', className : 'batch-table-header'},
//   { title: 'Conversation Id', dataIndex: 'conversation_id', key: 'conversation_id' , className : 'batch-table-header'},
//   { title: 'Call Status', dataIndex: 'call_status', key: 'call_status' , className : 'batch-table-header'},
//   {title: 'Action',
//     dataIndex: 'action',
//     key: 'action' ,
//     className : 'batch-table-header', 
//     align : 'center',
//     render : (text, record) => (
//       <>
//         <Tooltip title="View Payload">
//             <Button
//                 type="link"
//                 onClick={() => handleActionClick(record)}
//                 icon={<FileTextFilled style={{ color: "#1890ff" }}/>}
//             >
//             </Button>
//         </Tooltip>
//         <Tooltip title="View Conversation">
//             <Button
//                 type="link"
//                 onClick={() => viewConversation(record)}
//             >
//                 <EyeFilled />
//             </Button>
//         </Tooltip>
//     </>
//   ) }
// ]

const getDynamicColumns = (dataSource) => {
  if (!dataSource || dataSource.length === 0) return [];
  
  // Extract unique keys from data source for dynamic columns
  const keys = Object.keys(dataSource[0] || {}).filter(
    (key) => key !== 'action' && key !== 'record_payload'
  );

  // Map keys to column definitions
  return keys.map((key) => ({
    title:key.replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/_/g, ' ') // Replace underscores with spaces
    .toLowerCase()      // Convert the entire string to lowercase first
    .replace(/\b\w/g, (char) => char.toUpperCase()), 
    dataIndex: key,
    key: key,
    className: 'batch-table-header',
  }));
};

const actionColumn = {
  title: 'Action',
  dataIndex: 'action',
  key: 'action',
  className: 'batch-table-header',
  align: 'center',
  render: (text, record) => (
    <>
      <Tooltip title="View Payload">
        <Button
          type="link"
          onClick={() => handleActionClick(record)}
          icon={<FileTextFilled style={{ color: "#1890ff" }} />}
        />
      </Tooltip>
      <Tooltip title="View Conversation">
        <Button
          type="link"
          onClick={() => viewConversation(record)}
        >
          <EyeFilled />
        </Button>
      </Tooltip>
    </>
  ),
};

const expandedTable = (record) => {
    if (record.batches) {
      const dynamicColumns = getDynamicColumns(record.batches || []);
      const batchLevelColumns = [...dynamicColumns, actionColumn];
      return (
        <>
        <div className="expandedTableWrapper">
          <Table
            columns={batchLevelColumns}
            dataSource={record.batches || []}
            pagination={false}
            rowKey="batch_id" bordered
            size='small'
            className='expandedTable'
             scroll={{ x: 'max-content' }} 
              tableLayout="fixed"
          />
        </div>
          <Modal
            title="Payload Details"
            open={isModalVisible}
            onCancel={handleCloseModal}
            footer={[<Button key="close" onClick={handleCloseModal}>Close</Button>]}
          >
          {payload ? (
            <div>
              <Input
                placeholder="Search keys or values"
                prefix={<SearchOutlined />}
                onChange={handleSearchChange}
                value={searchText}
                style={{ marginBottom: '16px' }}
              />

              <div style={{maxHeight: '400px', overflowY: 'auto', padding: '8px', background: '#f9f9f9', borderRadius: '8px'}}>
                <Row gutter={[16, 8]}>
                  {filteredPayload.length > 0 ? (
                    filteredPayload.map(([key, value]) => (
                      <React.Fragment key={key}>
                        <Col span={10}>
                          <Text strong>{key}:</Text>
                        </Col>
                        <Col span={14}>
                          <Text>{value !== null && value !== undefined ? value.toString() : 'N/A'}</Text>
                        </Col>
                      </React.Fragment>
                    ))
                  ) : (
                    <Text type="secondary">No matching results</Text>
                  )}
                </Row>
              </div>
            </div>
          ) : (
            <p>No data available</p>
          )}
          </Modal>

        <ConversationModal 
                isModalOpen ={isConversationModalVisible} 
                onCloseModal ={closeModal} 
                conversationRecording ={conversationData.recordings || []}
                loadingConversation ={loading} 
                conversationMessages={conversationData.messages || []} 
            />
      </>
      );
    }

    return null;
  };

  const getOriginalProjectExcel = async (record) => {
    try {
        if (record.project_file_name){
            const link = document.createElement('a');
            link.href = process.env.REACT_APP_BOT_API_URI + "wfm/getProjectFile?fileName=" + record.project_file_name;
            link.download = record.project_file_name || 'download.xlsx';
            link.target = "_self";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else{
            message.error('Project File Unavailable')
        }
    } catch (error) {
        console.error("Error while downloading the file:", error);
    }
}

  const groupColumns = [
    { title: 'Project Name', dataIndex: 'project_name', key: 'project_name' , className : 'report-expanded-table-header', render: (text, record) => `${text} - ${record.group_name}`},
    { title: 'Project File', dataIndex: 'project_file_name', key: 'project_file_name' , className : 'report-expanded-table-header', 
      render: (text, record) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            getOriginalProjectExcel(record);
          }}
          className='anchor-link'
        >
          {text || 'No File'}
        </a>
      )
    },
  ];

  const onFilterClick = async () => {
    setFilterVisible(prevFilterVisible => !prevFilterVisible);
  }

const onFilterClose = async () => {
  setFilterVisible(false);
}

const onFilterReset = async () => {
  patientFilterForm.resetFields();
  setFilteredData(patientDetails); 
  setResetFlag(prev => !prev);
};

const handleSearchFilterSubmit = async () => {
  const values = patientFilterForm.getFieldsValue(); 
  const { Group, Project } = values;

  const filtered = patientDetails.filter(item => {
    const matchesGroup = Group && Group.length > 0 ? Group.includes(item.group_name) : true;
    const matchesProject = Project && Project.length > 0 ? Project.includes(item.project_name) : true;
    return matchesGroup && matchesProject;
  });

  setFilteredData(filtered); 
  setFilterVisible(false); 
}

const popoverContent  = (
  <div className='filterForm'>
       <Form
          name="basic" labelCol={{span: 5}} wrapperCol={{span: 18}}
          id="frmMessageFilter"
          form={patientFilterForm} onFinish={handleSearchFilterSubmit}
          style={{display: 'flex', flexDirection: 'column'}}
      >
           <Form.Item label="Group" name="Group">
               <Select
                  mode="multiple"
                  allowClear
                  // filterOption={false}
                  notFoundContent={null}
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().includes(input.toLowerCase());
                  }}
                  defaultActiveFirstOption={false}
                  getPopupContainer={trigger => trigger.parentNode}
                  placeholder="Group"
                  maxTagCount={2}
                  maxTagTextLength={10}
              >
                  {groupOptions.map((group) => (
                    <Select.Option key={group} value={group}>
                      {group}
                    </Select.Option>
                  ))}
               </Select>
           </Form.Item>
           <Form.Item label="Project" name="Project">
               <Select
                  mode="multiple"
                  allowClear
                  filterOption={(input, option) => {
                    return option.children.toLowerCase().includes(input.toLowerCase());
                  }}
                  notFoundContent={null}
                  defaultActiveFirstOption={false}
                  placeholder="Project"
                  maxTagCount={2}
                  maxTagTextLength={10}
              >
                  {projectOptions.map((project) => (
                    <Select.Option key={project} value={project}>
                      {project}
                    </Select.Option>
                  ))}
               </Select>
           </Form.Item>
          <Form.Item {...tailLayout}>
              <Space>
                  <Button type="primary" htmlType="submit">Apply</Button>
                  <Button htmlType="button" onClick={(e) => onFilterReset(e)}>Reset</Button>
                  <Button type="primary" onClick={() => onFilterClose()} className="custom-danger">Close</Button>
              </Space>
          </Form.Item>
      </Form>
  </div>
);

const expandedGroupInfo = (record) => {
  const relatedGroupInfo = filteredData.find((pat) => pat.patientData.identification === record.identification)?.groupInfo || [];
return(
  <>
        <Table
          dataSource={relatedGroupInfo}
          // dataSource={record.groupInfo}
          columns={groupColumns}
          rowKey={(record, index) => index}
          // rowKey={(record) => record.key}
          style={{width: '100%', overflowY: 'auto'}}
            className="reportGroupTable"
            pagination={false}
            tableLayout='fixed'
          bordered
          expandable={{
            expandedRowRender : expandedTable,
          }}
        />
  </>
)
}

const onRangeSelect = (value) => {
  setSelectedRange(value);
  let startDate, endDate;

  const currentDate = new Date();

  switch (value) {
    case 'thisMonth':
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1); 
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      break;
    case 'last3days':
      startDate = new Date();
      startDate.setDate(currentDate.getDate() - 3);
      endDate = currentDate;
      break;
    case 'lastMonth':
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      break;
    case 'custom':
      startDate = endDate = null;
      break;
    default:
      message.error('Invalid date range selected');
      return;
  }

  const formatDate = (date) =>
    date ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}` : null;

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  setDates([formattedStartDate, formattedEndDate]);

  if (value !== 'custom') {
    fetchPatientDetails([formattedStartDate, formattedEndDate]);
  }
};

  return (
    <div style={{ padding: '20px' }}>
      <div style={{display : 'flex',  justifyContent: 'space-between', alignItems: 'center'}}>
        <div>
          <Select
            mode="multiple"
            showSearch
            placeholder="Search Patient"
            onSearch={handleSearch}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            notFoundContent={null}
            defaultActiveFirstOption={false}
            filterOption={false}
            style={{ width: '320px', marginRight: '10px' }}
            value={patientId}
            maxTagCount={2}
          >
          {options.map(opt => (
              <Select.Option key={opt.patientId} value={opt.patientId}>{`${opt.firstName} ${opt.lastName}`}</Select.Option>
          ))}
          </Select>
          <Button onClick={() => fetchPatientDetails(dates)} loading={loading}>
            Search
          </Button>
        </div>
        <div>
        {/* <Tooltip title="Download Report">
              <Button size='large' type = 'link' style={{marginLeft : 3}} icon={<FileExcelFilled style={{marginTop : 3 , color : 'green', fontSize: '24px'}}/>}></Button>
          </Tooltip> */}
            {/* <Space>
                <Popover className='escalation-margin' placement="bottomRight" content={popoverContent}
                         autoAdjustOverflow={false}
                         trigger="click" open={filterVisible} mask={true}
                         overlayStyle={{
                             width: '400px',
                             overflow: 'auto',
                             boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.175)'
                         }}>
                    <Tooltip placement="bottomRight" title='Patient Filter'>
                        <Button className='filter-button' type="primary"
                                icon={<FilterFilled/>} onClick={() => onFilterClick()}
                        >
                        </Button>
                    </Tooltip>
                </Popover>
            </Space> */}

<Space>
  {/* <label style={{
            fontSize: '13.5px',
            fontWeight: '700',
            marginBottom: '8px',
            color: '#333',
          }}>Date Range </label> */}
<Select
  onChange={onRangeSelect}
  defaultValue="thisMonth"
  className='dateRange'
  style={{ width: '200px', fontSize: 13, fontWeight:'normal', 
    
    boxShadow:'0 2px 4px rgba(0, 0, 0, 0.1)' }}
>
  <Option value="last3days">Last 3 Days</Option>
  <Option value="thisMonth">Current Month</Option>
  <Option value="lastMonth">Last Month</Option>
  <Option value="custom">Custom Range</Option>
</Select>

{selectedRange === 'custom' && (
<>        
    <RangePicker 
      onChange={onDateChange} 
      format="YYYY-MM-DD" 
      placeholder={['Start date', 'End date']}
      style={{
      }}
      placement="bottomRight"
      className='custom-date-picker'
    />
</>
)}       
</Space>
        </div>
      </div>

    {patientinfo &&
      <Table dataSource={patientinfo} columns={patientColumns} 
      rowKey="identification" 
      style={{marginTop : 10}} 
      className="reportTable" 
      bordered
      // pagination={false}
      tableLayout='fixed'
      expandable={{
        expandedRowRender : expandedGroupInfo,
        // rowExpandable : 
      }}
      />
    }

    </div>
  );
};

export default PatientView;
